<template>
    <ra-box
        :class="[$route.path.startsWith('/dev') ? 'dev-layout' : '']"
        class="application-layout"
    >
        <web>
            <Header
                v-if="!noHeader"
                @menu="webDrawerOpen = true"
            />
            <aside
                v-if="!closeDrawer"
                :class="[webDrawerOpen && 'open-drawer']"
            >
                <ra-drawer :resizable="false">
                    <navigation-drawer />
                </ra-drawer>
                <div
                    class="close-container"
                    @click="webDrawerOpen = false"
                ></div>
            </aside>
            <ra-box tag="main">
                <v-progress-linear
                    v-if="loading"
                    v-model="loading"
                    :indeterminate="!isPercentage"
                    color="secondary"
                />
                <ra-stack
                    direction="horizontal"
                    distribution="fill"
                    gap="0"
                >
                    <v-fade-transition>
                        <ra-box
                            v-if="hasGoBack"
                            class="go-back-container"
                            flex="0 0 48px"
                            padding="6,"
                        >
                            <v-btn
                                color="primary"
                                icon="mdi-arrow-left"
                                variant="plain"
                                width="48px"
                                @click="$router.$history.back($route)"
                            />
                        </ra-box>
                    </v-fade-transition>
                    <ra-box
                        padding="8"
                        scroll-y
                    >
                        <slot></slot>
                    </ra-box>
                </ra-stack>
            </ra-box>
        </web>
        <tablet>
            <Header v-if="!noHeader" />
            <aside v-if="$slots.tabletAside">
                <ra-drawer :open="!closeDrawer">
                    <slot name="tabletAside"></slot>
                </ra-drawer>
            </aside>
            <ra-box tag="main">
                <slot></slot>
            </ra-box>
        </tablet>
    </ra-box>
</template>

<script>
    import Header from '@components/global/Header.vue'
    import NavigationDrawer from '@components/global/NavigationDrawer/NavigationDrawer.vue'
    import { mapState } from 'pinia'
    import { useGlobalLoading } from '@store/globalLoading.js'

    export default {
        props: {
            noHeader: {
                type: Boolean,
                default: false,
            },
            closeDrawer: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                webDrawerOpen: false,
            }
        },
        computed: {
            ...mapState(useGlobalLoading, ['loading', 'isPercentage']),
            hasGoBack() {
                return Boolean(this.goBack)
            },
            goBack() {
                return this.$router.$history.resolve(this.$route)
            },
        },
        components: {
            NavigationDrawer,
            Header,
        },
    }
</script>

<style lang="scss">
    .go-back-container {
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        position: relative;
        left: 15px;

        @media screen and (max-width: 740px) {
            display: none;
        }
    }

    .dev-layout {
        .navigation-drawer.bg-primary-gradient,
        *.bg-primary-gradient {
            background: linear-gradient(#336af5, #083bb4) !important;
        }
    }
</style>
