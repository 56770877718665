<script>
    export default {
        name: 'Loader',
        props: {
            waitFor: {
                default: false,
            },
            height: {
                type: String,
                default: '200px',
            },
        },
        computed: {
            isLoading() {
                if (this.waitFor instanceof Array)
                    return this.waitFor.length === 0
                return !Boolean(this.waitFor)
            },
        },
    }
</script>

<template>
    <slot v-if="!isLoading"></slot>
    <ra-box
        v-else
        :style="{ height }"
        class="loader-container"
    >
        <v-progress-circular
            color="primary"
            indeterminate
        ></v-progress-circular>
    </ra-box>
</template>

<style lang="scss" scoped>
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
