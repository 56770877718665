import { ResourcesRoutesName } from '@routes/Authentificated/Resources.names.js'
import { godRoutesMeta as meta } from '@routes/God/meta.js'

export default [
    //placed here to not be a child of resources and be in a different layout
    {
        path: '/resources/faqs/create',
        name: ResourcesRoutesName.faqs.create,
        meta,
        component: () => import('@pages/Ressources/FAQFormPage.vue'),
    },
    {
        path: '/resources/faqs/:faq_id/edit',
        name: ResourcesRoutesName.faqs.edit,
        meta,
        component: () => import('@pages/Ressources/FAQFormPage.vue'),
    },
]
