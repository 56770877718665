export const DevRoutesNames = {
    crashes: {
        index: 'dev.crashes.index',
        show: 'dev.crashes.show',
    },
    distributor: {
        index: 'dev.distributor.index',
        show: 'dev.distributor.show',
    },
    websocket:{
        index: 'dev.websocket.index',
    },
}
