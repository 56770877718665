<script>
    export default {
        props: {
            user: {
                type: Object,
                default: undefined,
            },
            size: {
                type: String,
                default: '45',
            },
        },
    }
</script>
<template>
    <template v-if="!user?.avatar?.url || hasError">
        <ra-box
            bg="primary"
            centered-content
            class="rounded-circle"
            :height="size + 'px'"
            :width="size + 'px'"
        >
            <ra-typo
                bold
                color="white"
                upper
                :style="{ fontSize: size * 0.4 + 'px' }"
            >
                {{ user?.firstname[0] }}{{ user?.lastname[0] }}
            </ra-typo>
        </ra-box>
    </template>
    <img
        v-else
        :height="size + 'px'"
        :width="size + 'px'"
        class="rounded-circle"
        :src="user?.avatar?.url"
        @error="hasError = true"
    />
</template>
