export class RouteHistory {
    constructor(route, goBackRoute) {
        this.route = route
        this.goBack = goBackRoute
    }
}

export class RouterHistory extends Map {
    static instance = null

    constructor(router) {
        super()
        this.router = router
    }

    static getInstance(router) {
        if (!this.instance) {
            this.instance = new RouterHistory(router)
        }
        return this.instance
    }

    resolve(locationRaw) {
        let route = this.router.resolve(locationRaw)
        if (!this.has(route.name)) return null
        let historyRoute = this.get(route.name)
        if (historyRoute?.goBack) return historyRoute.goBack
        else return null
    }

    back(locationRaw) {
        let backRoute = this.resolve(locationRaw)
        if (backRoute) this.router.push(backRoute)
    }
}
