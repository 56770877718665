<script>
    export default {
        name: 'ModuleCard',
        props: {
            module: {
                type: Object,
                default: () => {},
            },
        },
    }
</script>

<template>
    <ra-stack
        alignement="scretch"
        bg="white"
        class="module-card"
        direction="horizontal"
        distribution="fill"
        gap="6"
        rounded
        @click="$emit('click', module)"
    >
        <img
            :src="module?.image?.url"
            alt="module_image"
            style="display: block"
        />
        <ra-box
            class="text-module"
            padding="5,"
        >
            <ra-typo
                uppercase
                variant="x-small"
            >
                <span
                    :style="{ color: 'var(--pack-color)' }"
                    v-html="
                        module.is_intro
                            ? $t('formations.modules._intro')
                            : $tc('formations.modules._name', 1) +
                              ' <b>' +
                              module.number.toString().padStart(2, '0') +
                              '</b>'
                    "
                >
                </span>
                <span class="mx-2">
                    {{ '|' }}
                </span>
                <ra-typo span>
                    <v-icon class="mr-2">mdi-clock-outline</v-icon>
                    <ra-typo
                        bold
                        span
                        >{{ module.nb_minutes }}</ra-typo
                    >
                    MIN
                </ra-typo>
            </ra-typo>
            <ra-typo
                semi
                variant="title"
            >
                {{ module.name }}
            </ra-typo>
        </ra-box>
    </ra-stack>
</template>
<style lang="scss" scoped>
    .module-card {
        img {
            width: 170px;
            height: 100px;
            object-fit: cover;
        }
        .text-module {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            justify-content: center;
        }
    }

    [data-device='web'] {
        @media screen and (max-width: 768px) {
            .module-card {
                background: red;
                flex-direction: column;
                gap: 0em;
                div.py-5 {
                    padding: calc(5 * 4px) !important;
                    margin-top: 100px;
                }
                img {
                    width: 100%;
                    position: absolute;
                }
            }
        }
    }
</style>
